<template>
    <head>
        <link
            href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,900&family=Rubik&display=swap"
            rel="stylesheet">
    </head>
    <div class="outer">
        <div class="description">
            <div class="desc-top">
                <h1><span style="color: lightcoral">Hello</span>, I'm Carl!</h1>
                <img src="../assets/mindre-bild2.png" alt="Picture of me!" id="headshot">
            </div>
            <p>I am twenty-five years old and studied Game Programming at Yrgo,
                Gothenburg. I've always loved video games (and board games), and I am finally taking my first steps into
                creating them myself!<br><br>

                I'm most comfortable in Unity using C#, but I also took a class in Unreal Engine using Blueprints, and
                although it was different, It was also a lot of fun!<br><br>

                As for projects I have done a few solo and some in groups, and feel pretty
                comfortable in both. But working with a team is in my opinion a far superior experience.</p>
        </div>
    </div>
</template>

<script>
import { transcode } from 'buffer';

export default {

}
</script>

<style scoped>
.outer {
    display: flex;
    width: 80%;
    margin: auto;
}

h1 {
    color: white;
    font-family: 'PlayFair Display';
    font-size: 75px;
    margin: auto;
    text-align: center;
}

#headshot {
    border-radius: 50%;
    transform: scaleX(-1);
    filter: opacity(50%) drop-shadow(0 0 0 lightcoral);
    max-width: 300px;
    height: auto;
    margin: auto;
}


.description p {
    font-size: 27px;
    font-family: 'Rubik';
    border-radius: 10px;
    background-color: #344b61;
    padding: 20px;
}

.desc-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
</style>