<template>
    <head>
        <link
            href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,900&family=Rubik&display=swap"
            rel="stylesheet">
    </head>
    <nav :class="navBarScroll()">
        <router-link to="/" id="logo">Carl Christensen</router-link>
        <div class="routerLinks">
            <router-link to="/" id="innerEdge" class="routerButton">Home</router-link>
            <router-link to="/projects" class="routerButton">Projects</router-link>
            <a href="https://www.linkedin.com/in/carl-christensen-254b301b8/" class="socialLink" title="LinkedIn"
                target="_blank" rel="noreferrer noopener">
                <img src="../assets/LinkedIn_coral.png" alt="">
            </a>
            <a href="https://github.com/WashFall/" class="socialLink" title="GitHub" target="_blank"
                rel="noreferrer noopener">
                <img src="../assets/gitHub_Icon.png" alt="">
            </a>
            <a @click="copyURL('washfall', 1)" class="socialLink fakeA" title="@washfall">
                <img src="../assets/discord_Icon.png" alt="">
                <Transition name="fade" @enter="enter(1)">
                    <img v-if="show1" src="../assets/copyText.png" id="copy" alt="">
                </Transition>
            </a>
            <a @click="copyURL('carl@carlchristensen.se', 2)" class="socialLink fakeA" id="outerEdge"
                title="carl@carlchristensen.se">
                <img src="../assets/mail_Icon.png" alt="">
                <Transition name="fade" @enter="enter(2)">
                    <img v-if="show2" src="../assets/copyText.png" id="copy" alt="">
                </Transition>
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            active: false,
            show1: false,
            show2: false
        }
    },
    methods: {
        navBarScroll: function () {
            if (this.active == false) {
                return ''
            } else {
                return 'sticky'
            }
        },
        async copyURL(mytext, num) {
            try {
                await navigator.clipboard.writeText(mytext);
                if (num === 1) {
                    this.show1 = true;
                }
                else if (num === 2) {
                    this.show2 = true;
                }
            } catch ($e) {
                alert('Cannot copy');
            }
        },
        enter: function (num) {

            var that = this;

            if (num === 1) {
                setTimeout(function () {
                    that.show1 = false;
                }, 1000);
            }
            else if (num === 2) {
                setTimeout(function () {
                    that.show2 = false;
                }, 1000);
            }
        }
    },
    mounted() {
        window.document.onscroll = () => {
            if (window.scrollY > 0) {
                this.active = true;
            } else {
                this.active = false;
            }
        }
    }
}
</script>

<style scoped>
nav {
    padding: 20px;
    background-color: #2c3e50;
    display: flex;
    width: 100%;
    z-index: 2;
    margin-bottom: 20px;
}

nav a {
    font-family: 'Rubik';
    font-weight: bold;
    color: #2c3e50;
    margin: auto 50px auto 0;
    font-size: 30px;
    text-decoration: none;
}

.routerLinks {
    margin: auto;
    margin-top: 20px;
}

nav a.router-link-exact-active {
    color: white;
    border-color: white;
    border-style: solid;
}

#logo {
    font-size: 50px;
    color: white;
    font-family: 'Playfair Display';
    font-style: italic;
    margin: auto;
    white-space: nowrap;
    border: none;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
}

.routerButton {
    border-radius: 3px;
    background-color: lightcoral;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.socialLink {
    width: 40px;
    margin-right: 20px;
    vertical-align: sub;
}

.socialLink img {
    width: inherit;
    vertical-align: -16%;
}

.fakeA :hover {
    cursor: pointer;
}

#copy {
    position: absolute;
    transform: translate(-100%, -120%);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

@media screen and (max-width: 1000px) {
    nav {
        display: flex;
        flex-wrap: wrap;
    }

    .socialLink {
        width: 30px;
    }

    .routerLinks {
        margin-top: auto;
    }

    .routerLinks a {
        font-size: 20px;
    }

    #logo {
        font-size: 30px;
    }

    #innerEdge {
        margin-left: 1em;
    }

    #outerEdge {
        margin-right: 0;
    }
}

@media screen and (max-width: 839px) {
    #innerEdge {
        margin-left: 0;
    }

    .routerLinks a {
        margin-right: 15px;
    }
}

@media screen and (max-width: 638px) {
    .routerLinks a {
        margin-right: 10px;
    }

    .routerLinks {
        margin-top: 10px;
    }
}

@media screen and (max-width: 342px) {
    .routerLinks a {
        font-size: 15px;
    }

    .socialLink {
        width: 25px;
    }
}
</style>