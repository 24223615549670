<template>
    <div class="slideshow">
        <slot></slot>

        <a class="prev" @click="$emit('prev')">&#10094;</a>
        <a class="next" @click="$emit('next')">&#10095;</a>
    </div>
</template>

<script>

</script>

<style scoped>
.slideshow {
    position: relative;
    width: 480px;
    height: 270px;
    overflow: hidden;
}

a {
    position: absolute;
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
    text-align: center;
    font-size: 28px;
    user-select: none;
    border-radius: 50%;
    background-color: hsla(0, 0%, 50%, 0.15);

}

a:focus,
a:hover {
    cursor: pointer;
}

.next {
    right: 0;
}

.prev {
    left: 0;
}
</style>