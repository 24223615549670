<template>
    <h1 class="title">{{ gameData.name }}</h1>
    <div class="outer">
        <div class="inner">
            <p class="panel" id="list">
            <h2>What I worked on</h2>
            <div style="width: 240px">
                <li v-for="thing in gameData.list">{{ thing }}</li>
            </div>
            </p>
            <div class="panel">
                <Slideshow @next="next" @prev="prev">
                    <Slide :index="0" :visibleSlide="visibleSlide" :direction="direction">
                        <iframe :src="gameData.youtube" frameborder="0" width="480" height="270"></iframe>
                    </Slide>
                    <Slide v-for="(slide, index) in gameData.images" :key="slide" :index="index + 1"
                        :visibleSlide="visibleSlide" :direction="direction">
                        <img :src="require(`../assets/${slide}`)" alt="">
                    </Slide>
                </Slideshow>
            </div>
            <p class="panel">
            <h2>Role description</h2>
            {{ gameData.myRole }}
            </p>
            <p class="panel">
            <h2>Game description</h2>
            {{ gameData.gameDesc }}
            <a v-if="gameData.download != ''" :href="gameData.download">
                {{ gameData.available }}
            </a>
            </p>
        </div>
    </div>
</template>

<script>
import sourceData from "@/data.json"
import Slide from "@/components/Slide.vue"
import Slideshow from "@/components/Slideshow.vue"

export default {
    computed: {
        gameId() {
            return this.$route.params.id
        },
        gameData() {
            return sourceData.games.find(game => game.siteID == this.gameId)
        },
        getLength() {
            let objectSize = 0;
            for (let k in this.gameData.images) {
                objectSize++
            }
            return objectSize
        }
    },
    data() {
        return {
            visibleSlide: 0,
            direction: 'left'
        }
    },
    components: {
        Slide,
        Slideshow
    },
    methods: {
        next() {
            if (this.visibleSlide >= this.getLength) {
                this.visibleSlide = 0;
            }
            else {
                this.visibleSlide++;
            }
            this.direction = 'left';
        },
        prev() {
            if (this.visibleSlide <= 0) {
                this.visibleSlide = this.getLength;
            }
            else {
                this.visibleSlide--;
            }
            this.direction = 'right';
        }
    }
}
</script>

<style scoped>
.outer {
    margin: auto;
    width: 74%;
}

img {
    width: 100%;
}

iframe {
    border-style: none;
}

.title {
    text-align: center;
    font-family: 'Rubik';
    font-size: 40px;
}

.panel {
    height: 270px;
    width: 480px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

p,
li {
    font-family: 'Rubik';
    font-size: 20px;
}

h2 {
    font-family: 'Rubik';
    font-size: 35px;
    margin: 0;
    padding: 0;
}

a {
    font-family: 'Rubik';
    font-size: 25px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    background-color: lightcoral;
    height: 1em;
    padding: 4px 10px 8px 10px;
    border-radius: 5px;
    margin-top: 30px;
}

.inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>