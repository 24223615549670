<template>
    <router-link :to="'/projects/' + siteID" class="panel">
        <div>
            <img :src="require(`../assets/${imageName}`)" alt="{{ title }}" id="pic">
        </div>
        <div class="container">
            <h3>
                <span>{{ title }}</span>
            </h3>
            <p>
                <span>{{ description }}</span>
            </p>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
        imageName: String,
        siteID: String
    }
}
</script>

<style scoped>
.panel {
    border-radius: 10px;
    background-color: #344b61;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.441);
    transition: 0.3s;
    text-decoration: none;
    color: white;
}

.panel:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.892);
    cursor: pointer;
}

#pic {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: 175px;
    object-fit: cover;
}

.container {
    padding: 1em;
    font-family: 'Rubik';
    font-size: 16px;
}

.container h3 {
    margin-top: 0;
    margin-bottom: .5em;
    font-weight: bold;
    font-family: 'Rubik';
    font-size: 22px;
}
</style>