<template>
    <div class="outer">
        <div class="inner">
            <Project v-for="game in games" :title="game.name" :description="game.shortDesc" :imageName="game.images[0]"
                :siteID="game.siteID" class="card">
            </Project>
        </div>
    </div>
</template>


<script>
import sourceData from "@/data.json"
import Project from '@/components/Project.vue'
export default {
    components: {
        Project
    },
    data() {
        return {
            games: sourceData.games
        }
    }
}

</script>

<style scoped>
.outer {
    margin: auto;
    width: 80%;
}

.card {
    flex: 1 0 500px;
    box-sizing: border-box;
    margin: 1rem .25em;
}

@media screen and (min-width: 40em) {
    .inner {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        flex: 0 1 calc(50% - 1em);
        margin-left: 8px;
        margin-right: 8px;
    }
}

@media screen and (min-width: 60em) {
    .card {
        flex: 0 1 calc(25% - 1em);
    }
}
</style>